/*
    To add a new icon:
        1. Store SVG in assets/images/icons
        2. Remove width and height attributes from SVG
        3. Replace any style classes in SVG with inline attributes (otherwise classes from one SVG could interfere with another)
        4. Replace any configurable colours in SVG with CSS `currentColor`
        5. Import SVG in this file and add it to `getSVG` function and to `variant` proptypes
*/

import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';

import useAsyncButtonAction from '../../hooks/useAsyncButtonAction';
import useTooltip from '../../hooks/useTooltip';

import Spinner from '../spinner';
import Tooltip from '../tooltip';
import { IconButton, IconImage } from './Icon.styles';

import { ReactComponent as Analytics } from '../../assets/images/icons/analytics.svg';
import { ReactComponent as ArrowsMove } from '../../assets/images/icons/arrows-move.svg';
import { ReactComponent as Audio } from '../../assets/images/icons/audio.svg';
import { ReactComponent as AudioOff } from '../../assets/images/icons/audio-off.svg';
import { ReactComponent as AudioOn } from '../../assets/images/icons/audio-on.svg';
import { ReactComponent as Bell } from '../../assets/images/icons/bell.svg';
import { ReactComponent as Camera } from '../../assets/images/icons/cameras.svg';
import { ReactComponent as Close } from '../../assets/images/icons/close.svg';
import { ReactComponent as CloudAdapter } from '../../assets/images/icons/cloud-adapter.svg';
import { ReactComponent as Collapse } from '../../assets/images/icons/collapse.svg';
import { ReactComponent as Connection } from '../../assets/images/icons/connection.svg';
import { ReactComponent as Delete } from '../../assets/images/icons/delete.svg';
import { ReactComponent as Download } from '../../assets/images/icons/download.svg';
import { ReactComponent as Edit } from '../../assets/images/icons/edit.svg';
import { ReactComponent as Fullscreen } from '../../assets/images/icons/fullscreen.svg';
import { ReactComponent as Hide } from '../../assets/images/icons/hide.svg';
import { ReactComponent as Information } from '../../assets/images/icons/information.svg';
import { ReactComponent as Motion } from '../../assets/images/icons/motion.svg';
import { ReactComponent as Pause } from '../../assets/images/icons/pause.svg';
import { ReactComponent as Picture } from '../../assets/images/icons/pic.svg';
import { ReactComponent as Play } from '../../assets/images/icons/play.svg';
import { ReactComponent as Playback } from '../../assets/images/icons/playback.svg';
import { ReactComponent as Prohibition } from '../../assets/images/icons/prohibition.svg';
import { ReactComponent as Recording } from '../../assets/images/icons/recording.svg';
import { ReactComponent as Refresh } from '../../assets/images/icons/refresh.svg';
import { ReactComponent as Schedule } from '../../assets/images/icons/schedule.svg';
import { ReactComponent as Share } from '../../assets/images/icons/share.svg';
import { ReactComponent as Show } from '../../assets/images/icons/show.svg';
import { ReactComponent as Shrink } from '../../assets/images/icons/shrink.svg';
import { ReactComponent as Site } from '../../assets/images/icons/site.svg';
import { ReactComponent as Tag } from '../../assets/images/icons/tag.svg';
import { ReactComponent as Thermometer } from '../../assets/images/icons/thermometer.svg';
import { ReactComponent as Time } from '../../assets/images/icons/time.svg';
import { ReactComponent as Warning } from '../../assets/images/icons/warning.svg';

const getSVG = (variant) => {
    switch (variant) {
        case 'analytics':
            return Analytics;
        case 'arrows-move':
            return ArrowsMove;
        case 'audio':
            return Audio;
        case 'audio-off':
            return AudioOff;
        case 'audio-on':
            return AudioOn;
        case 'bell':
            return Bell;
        case 'camera':
            return Camera;
        case 'close':
            return Close;
        case 'cloud-adapter':
            return CloudAdapter;
        case 'collapse':
            return Collapse;
        case 'connection':
            return Connection;
        case 'delete':
            return Delete;
        case 'download':
            return Download;
        case 'edit':
            return Edit;
        case 'fullscreen':
            return Fullscreen;
        case 'hide':
            return Hide;
        case 'information':
            return Information;
        case 'motion':
            return Motion;
        case 'pause':
            return Pause;
        case 'image':
            return Picture;
        case 'play':
            return Play;
        case 'playback':
            return Playback;
        case 'prohibition':
            return Prohibition;
        case 'recording':
            return Recording;
        case 'refresh':
            return Refresh;
        case 'schedule':
            return Schedule;
        case 'share':
            return Share;
        case 'shrink':
            return Shrink;
        case 'site':
            return Site;
        case 'show':
            return Show;
        case 'tag':
            return Tag;
        case 'thermometer':
            return Thermometer
        case 'time':
            return Time;
        case 'warning':
            return Warning;
        default:
            return null;    
    }
};

/** Renders small image, usually SVG. */
const Icon = forwardRef((props, ref) => {
    if (props.asyncAction) {
        return <AsyncActionIcon {...props} ref={ref} />;
    } else {
        return <IconComponent {...props} ref={ref} />;
    }
});

Icon.displayName = 'Icon';

const IconComponent = forwardRef(
    (
        {
            variant,
            customSrc,
            onClick,
            accessibilityDescription,
            maxWidth,
            maxHeight,
            width,
            height,
            disabled = false,
            loading,
            colour,
            hoverColour,
            tooltipPlacement='top',
            tooltip = false,
            ...props
        },
        ref
    ) => {
        const [tooltipProps, triggerProps] = useTooltip({ placement: tooltipPlacement, disabled, children: accessibilityDescription });
        // useMemo stops SVG being rebuilt on every render
        const Component = useMemo(() => {
            if (variant) {
                const SVG = getSVG(variant);
                if (SVG) {
                    return forwardRef((props, ref) => <SVG aria-label={accessibilityDescription} {...props} ref={ref} />);
                }
            } else if (customSrc) {
                return props => <IconImage src={customSrc} alt={accessibilityDescription} {...props} />;
            }
        }, [accessibilityDescription, customSrc, variant]);
        

        if (!Component) {
            // `variant` not supported and no `customSrc` specified
            return accessibilityDescription;
        }

        if (onClick) {
            return (
                <IconButton
                    type="button"
                    onClick={loading ? undefined : event => {
                        event.stopPropagation();
                        onClick(event);
                    }}
                    disabled={disabled}
                    $width={width}
                    $height={height}
                    $hoverColour={hoverColour}
                    ref={ref}
                    aria-label={accessibilityDescription}
                    {...props}
                >
                    <Component
                        style={{
                            maxHeight,
                            maxWidth,
                            width: width ?? (maxWidth || maxHeight || height ? 'auto' : 30),
                            height: height,
                            opacity: loading ? 0 : disabled ? 0.4 : undefined,
                            color: colour
                        }}
                    />
                    {loading && <Spinner style={{ position: 'absolute' }} />}
                </IconButton>
            );
        } else {
            const iconComponent = <Component
                {...props}
                style={{
                    maxHeight,
                    maxWidth,
                    width: width ?? (maxWidth || maxHeight || height ? 'auto' : 30),
                    height: height,
                    opacity: disabled ? 0.4 : undefined,
                    color: colour,
                    ...(props.style ?? {})
                }}
                ref={ref}
            />;
            if (tooltip) {
                return (
                    <div 
                        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
                        {...triggerProps}
                    >
                        {iconComponent}
                        {tooltip && (
                                <Tooltip
                                    {...tooltipProps}
                                >
                                    {accessibilityDescription}
                                </Tooltip>
                            )
                        }
                    </div>
                );
            } else {
                return iconComponent;
            }
        }
    }
);

const AsyncActionIcon = forwardRef(({ asyncAction, ...props }, ref) => {
    const asyncActionProps = useAsyncButtonAction(asyncAction);

    return <IconComponent {...asyncActionProps} {...props} ref={ref} />;
});

Icon.propTypes = {
    /* Icon to use. */
    variant: PropTypes.oneOf([
        'analytics',
        'arrows-move',
        'audio',
        'audio-off',
        'audio-on',
        'bell',
        'camera',
        'close',
        'cloud-adapter',
        'collapse',
        'connection',
        'delete',
        'download',
        'edit',
        'fullscreen',
        'hide',
        'information',
        'motion',
        'pause',
        'image',
        'play',
        'playback',
        'prohibition',
        'recording',
        'refresh',
        'schedule',
        'share',
        'show',
        'shrink',
        'site',
        'tag',
        'thermometer',
        'time',
        'warning'
    ]),
    /** Image src to use instead of one of the preset variants. */
    customSrc: PropTypes.string,
    /* Call when icon clicked. */
    onClick: PropTypes.func,
    /* Description of icon image/button. */
    accessibilityDescription: (props, propName, componentName) => {
        const value = props[propName];
        if (!value && props['aria-hidden'] !== 'true') {
            return new Error(`${componentName} must either receive prop ${propName} or have aria-hidden="true".`)
        }
        if (value && typeof value !== 'string') {
            return new Error(`Prop ${propName} supplied to ${componentName} must be a string.`)
        }
    },
    /* Width for image (e.g. `50px`). If provided as a string, it must have units. */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /* Height for image (e.g. `50px`). If provided as a string, it must have units. */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /* Max width for image (e.g. `50px`). */
    maxWidth: PropTypes.string,
    /* Max height for image (e.g. `50px`). */
    maxHeight: PropTypes.string,
    /** Colour of icon. If left undefined, icon will use whatever is the current CSS text colour. */
    colour: PropTypes.string,
    /** Colour of icon on hover. Only supported when both `variant` and `onClick` props are used. */
    hoverColour: PropTypes.string,
    /* Grey out icon and do not call onClick when clicked. */
    disabled: PropTypes.bool,
    /** Loading spinner shown instead of image and button cannot be clicked. Ignored if no `onClick` prop given. */
    loading: PropTypes.bool,
    /** Function called on click. It takes one argument which is a callback that must be invoked when function has finished. Icon will show loading state until callback invoked. Do not use `onClick` when using this prop. */
    asyncAction: PropTypes.func,
};

export default Icon;
