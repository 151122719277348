// Returns src if it is a valid path to an image
// Returns fallback if not
const imagePromise = (src: string, fallback: string) => {
    return new Promise<void>((resolve, reject) => {
        const i = new Image();

        i.onload = () => {
            i.decode ? i.decode().then(resolve).catch(reject) : resolve();
        };

        i.onerror = reject;
        i.src = src;
    })
        .then(() => src)
        .catch(() => fallback);
};

export default imagePromise;

export const imageLoadsSuccessfully = (src: string) => {
    return new Promise<void>((resolve, reject) => {
        const i = new Image();

        i.onload = () => {
            i.decode ? i.decode().then(resolve).catch(reject) : resolve();
        };

        i.onerror = reject;
        i.src = src;
    })
        .then(() => true)
        .catch(() => false);
};

export const getImageSrcForBase64 = (base64: string) => `data:image;base64,${base64}`;


/**
 * Crop an image to the given coordinates.
 * @param image Original image as string url or Image instance.
 * @param coordinates Cropped coordinates as percentage integers between 0 and 100 inclusive.
 * @param desiredAspectRatio Extend the cropped coordinates as needed to produce a cropped image in this aspect ratio.
 * @returns URL of cropped image.
 */
export const createCroppedImage = (image: string | HTMLImageElement, coordinates: { x1: number; y1: number; x2: number; y2: number; }, desiredAspectRatio?: number) => new Promise<string>((res, rej) => {
    const originalImage = new Image();
    originalImage.src = image instanceof Image ? image.src : image;

    originalImage.onload = async () => {

        try {
            const imageWidth = originalImage.width;
            const imageHeight = originalImage.height;

            // Width and height of coordinates
            const croppedWidth = (coordinates.x2 - coordinates.x1)/100 * imageWidth;
            const croppedHeight = (coordinates.y2 - coordinates.y1)/100 * imageHeight;

            const croppedAspectRatio = croppedWidth / croppedHeight;

            // We'll adjust these variables to transform the cropped image to the desired aspect ratio (if applicable)
            let { x1, x2, y1, y2 } = coordinates;

            if (desiredAspectRatio) {

                // Function to extend the start and end coordinates in a given axis by the specified percentage
                // E.g. if we want to extend x1 of 20 and x2 of 30 by 10, it will return 15 and 35
                // It will also consider the image boundaries, so extending 5 and 10 by 20 will return 0 and 25
                const extendCoordinates = (c1: number, c2: number, percentageToGain: number) => {
                    // Move the coordinates apart evenly by the specified amount
                    let extendedC1 = c1 - percentageToGain / 2;
                    let extendedC2 = c2 + percentageToGain / 2;

                    // If this range is now invalid, move it whilst trying to preserve the new distance between them
                    if (extendedC1 < 0) {
                        extendedC2 = Math.min(extendedC2 - extendedC1, 100);
                        extendedC1 = 0;
                    } else if (extendedC2 > 100) {
                        extendedC1 = Math.max(extendedC1 - (extendedC2 - 100), 0);
                        extendedC2 = 100;
                    }
                    return [extendedC1, extendedC2];
                }

                // Adjust height or width of cropped image to achieve desired aspect ratio
                if (croppedAspectRatio > desiredAspectRatio) {
                    // Cropped image is wider than desired aspect ratio, so we need to extend height
                    const heightToGain = (1 / desiredAspectRatio) * croppedWidth - croppedHeight;

                    const heightToGainAsPerctange = (heightToGain / imageHeight) * 100;

                    [y1, y2] = extendCoordinates(y1, y2, heightToGainAsPerctange);
                } else if (croppedAspectRatio < desiredAspectRatio) {
                    // Cropped image is taller than desired aspect ratio, so we need to extend width
                    const widthToGain = desiredAspectRatio * croppedHeight - croppedWidth;

                    const widthToGainAsPercentage = (widthToGain / imageWidth) * 100;

                    [x1, x2] = extendCoordinates(x1, x2, widthToGainAsPercentage);
                }
            }

            // Draw cropped image onto a canvas
            const canvas = document.createElement('canvas');
            canvas.width = (x2 - x1)/100 * imageWidth;
            canvas.height = (y2 - y1)/100 * imageHeight;
            const context = canvas.getContext('2d');

            if (!context) {
                return rej(new Error('Could not get 2D context from canvas'));
            }

            context.drawImage(
                originalImage,
                x1/100 * imageWidth,
                y1/100 * imageHeight,
                (x2 - x1)/100 * imageWidth,
                (y2 - y1)/100 * imageHeight,
                0,
                0,
                canvas.width,
                canvas.height
            );
            
            canvas.toBlob(
                blob => {
                    if (blob) {
                        const url = URL.createObjectURL(blob);
                        res(url);
                    } else {
                        rej(new Error('Error getting blob from canvas'));
                    }
                },
                'image/jpeg'
            );
        } catch (error) {
            rej(error);
        }
    }
});